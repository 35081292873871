@font-face {
  font-family: 'VCR OSD Kanako';
  src: url('../assets/fonts/VCROSDKanako.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'iAWriterDuoS';
  src: url('../assets/fonts/iAWriterDuoS-Regular.woff2') format('woff2');
  font-display: swap;
}
