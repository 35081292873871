#search {
  position: fixed;
  bottom: 290px;
  left: 15px;

  @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
    left: 20px;
  }

  input {
    width: 31px;
    height: 31px;
    padding: 6px 15px 8px 15px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    transition-delay: 0s;
    border: 1px solid $primary;
    background-color: transparent;
    color: $primary;
    cursor: pointer;

    &::placeholder {
      color: transparent;
    }

    &:focus-visible {
      width: 250px;
      transition-delay: 0.25s;
      border-radius: 0;
      outline: 0;
      background-color: #151515;
      cursor: text;

      &::placeholder {
        color: $gray-600;
      }

      & + svg {
        opacity: 0;
      }
    }
  }

  svg {
    position: absolute;
    top: 7px;
    left: 7px;
    width: 18px;
    height: 18px;
    transition: all 0.5s ease-in-out;
    opacity: 1;
    pointer-events: none;

    * {
      fill: $primary;
    }
  }
}

#search-results {
  position: absolute;
  z-index: 999999999;
  bottom: 291px;
  left: 15px;
  width: 250px;
  transform: translateY(100%);
  border: 1px solid $primary;
  background-color: #151515;

  @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
    left: 20px;
  }

  a {
    display: flex;
    align-items: center;
    height: 31px;
    padding: 6px 15px 8px 15px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:not(:last-child) {
      border-bottom: 1px solid $primary;
    }

    &.is-selected {
      background-color: $primary;
      color: #151515;
    }
  }
}
