* {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

header {
  position: absolute;
  top: 20px;
  left: 15px;

  @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
    top: 40px;
    left: 60px;
  }

  img {
    height: 45px;

    @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
      height: 56px;
    }
  }

  h1 {
    position: relative;
    top: 6px;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    text-transform: uppercase;

    @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
      margin-left: 30px;
      font-size: 40px;
      line-height: 40px;
    }
  }
}

#back-to-site {
  display: block;
  margin-top: 20px;
  color: $pink;
  font-size: 20px;
  text-decoration: none;
  text-transform: uppercase;
}

.btn {
  display: inline-flex;
  align-items: center;
  min-height: 31px;
  text-transform: uppercase;

  &:hover {
    background-color: $pink !important;
    color: $white !important;
  }
}
