#page-finder {
  display: flex;
  position: fixed;
  bottom: 240px;
  left: 15px;

  @media (min-width: #{map-get($grid-breakpoints, 'md')}) {
    left: 20px;
  }

  input,
  select {
    height: 31px;
    color: $pink;
    font-family: 'VCR OSD Kanako', sans-serif;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
  }

  input {
    width: 50px;
    border: 1px solid $pink;
    outline: 0;
  }

  .dropdown {
    background-color: #1a1a1f;

    .btn {
      width: 100%;
      white-space: nowrap;

      span {
        display: block;
        overflow: hidden;
      }
    }
  }

  &.is-loading {
    & > div {
      opacity: .5;
      pointer-events: none;
    }
  }
}

.dropdown-menu {
  white-space: nowrap;

  li {
    padding: 6px 12px;
  }

  a {
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
  }
}

#btn-toggle-page-finder,
#btn-find-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 31px;
  border: 1px solid $pink;
  font-family: 'VCR OSD Kanako', sans-serif;
  line-height: 1em;
  text-transform: uppercase;

  &.active,
  &:hover {
    background-color: $pink;
    color: $black;
  }

  span {
    position: relative;
    top: -1px;
  }
}

#btn-toggle-page-finder {
  path {
    fill: $pink;
  }

  &.active,
  &:hover {
    path {
      fill: $white;
    }
  }
}

#btn-find-page {
  border-left-width: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
